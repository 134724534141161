import { CheckCircle } from "@mui/icons-material";
import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { styled,makeStyles } from "@mui/styles";
import { i18n } from "../../translate/i18n";
const StyledDialog = styled(Dialog)`


    .MuiDialogTitle-root {
        font-size: 24px;
        font-weight: bold;
        padding: 24px;
    }

    .MuiDialogContent-root {
        padding: 24px;
    }

    .MuiDialogActions-root {
        padding: 16px 24px;
    }
`;

const useStyles = makeStyles(theme => ({
    multFieldLine: {
        display: "flex",
        justifyContent: "space-between",
        "& > *:last-child": {
            marginRight: 0,
        },
        width: "100%",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export function SearchParamModal({ modalOpen, onClose,searchParamState }) {
    const [searchBy, setSearchBy] = React.useState("All");
    const [status, setStatus] = React.useState("All");
    const classes = useStyles();
    const now = new Date();

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };

    const handleSelectStatus = (event) => {
        setStatus(event.target.value);
    };

    
    const handleClose = () => {
        onClose();
    };


    const handleStatusChange = () => { 
        searchParamState((prevState) => ({
            ...prevState,
            filter: searchBy,
            status,
            dateRange: {
                dateStart: document.getElementById("dateStart").value,
                dateEnd: document.getElementById("dateEnd").value
            }
        }))
        handleClose();
    }



    return (
        <StyledDialog open={modalOpen}  onClose={handleClose} maxWidth="sm"
        fullWidth
        scroll="paper" >
            <DialogTitle>
                <Typography variant="h5"> {i18n.t("translation.SearchParamsModal.title")}</Typography>
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant="h6">{i18n.t("translation.SearchParamsModal.params.title")}</Typography>
                <Select value={searchBy} onChange={handleSearchByChange} margin='dense' size="small" fullWidth>
                <MenuItem value="All">{i18n.t("translation.SearchParamsModal.params.all")}</MenuItem>
                    <MenuItem value="Contact_name">{i18n.t("translation.SearchParamsModal.params.contactName")}</MenuItem>
                    <MenuItem value="Contact_number">{i18n.t("translation.SearchParamsModal.params.contactNumber")}</MenuItem>
                    <MenuItem value="User_name">{i18n.t("translation.SearchParamsModal.params.UserName")}</MenuItem>
                <MenuItem value="Ticket_number">
                {i18n.t("translation.SearchParamsModal.params.TicketNumber")}
                </MenuItem>
                    <MenuItem value="Message">{i18n.t("translation.SearchParamsModal.params.Message")}</MenuItem>

                </Select>
                <Typography variant="h6" sx={{
                    paddingBottom:'10px'
                } }>{i18n.t("translation.SearchParamsModal.params.CreatedAt")}:</Typography>

                <div className={classes.multFieldLine}>
                    <TextField
                        ampm="false"
                        autoFocus
                        className={classes.textField}
                        defaultValue={now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + (now.getDate() - 15)).slice(-2)}
                        id="dateStart"
                        InputLabelProps={{ shrink: true }}
                        label={`${i18n.t("translation.dashboard.dateFilterModal.dateRange.start")}`}
                        size="small"
                        type="date"
                        variant="outlined"
                    />
                    <TextField
                        ampm="false"
                    sx={{
                        flex: 1}}
                        defaultValue={now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2)}
                        id="dateEnd"
                        InputLabelProps={{ shrink: true }}
                        label={`${i18n.t("translation.dashboard.dateFilterModal.dateRange.end")}`}
                        size="small"
                        type="date"
                        variant="outlined"
                    />
                </div>
                <Typography variant="h6">Status:</Typography>
                <Select margin='dense' size="small" fullWidth value={status} onChange={handleSelectStatus} >
                <MenuItem value="All">{i18n.t("translation.SearchParamsModal.status.all")}</MenuItem>
                <MenuItem value="open">  {i18n.t("translation.ticketsList.assignedHeader")}</MenuItem>
                <MenuItem value="pending">
                {i18n.t("translation.ticketsList.pendingHeader")}
                </MenuItem>
                    <MenuItem value="waiting">{i18n.t("translation.tickets.tabs.waiting.title")}</MenuItem>
                    <MenuItem value="closed">{i18n.t("translation.tickets.tabs.closed.title")}</MenuItem>
                </Select>
            </DialogContent>



            <Divider sx={{ my: 2 }} />

            <DialogActions>
                <Button color="error" onClick={handleClose}>
                {i18n.t("translation.validation.buttons.cancel")}
                </Button>
                <Button
                    onClick={handleStatusChange}
                    color="primary"
                    variant="contained"
                    startIcon={<CheckCircle />}
                >
                     {i18n.t("translation.validation.buttons.filter")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}
