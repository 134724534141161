import React, { useState } from "react";

import { Button, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import NewChatModal from "../NewChatModal";
import ChatsList from "../ChatsList";

import { i18n } from "../../translate/i18n";

import TutorialModalButton from "../../components/TutorialModalButton";
import { useNavigate } from "react-router-dom";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
    },

    ticketsList: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 0,
    },

    tabsHeader: {
        flex: "none",
        backgroundColor: "#f0f2f5",
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fafafa",
        padding: theme.spacing(1),
    },

    serachInputWrapper: {
        flex: 1,
        background: "#fff",
        display: "flex",
        borderRadius: theme.spacing(2),
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: "grey",
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        borderRadius: theme.spacing(2),
    },

    badge: {
        right: "-10px",
    },
    show: {
        display: "block",
    },
    hide: {
        display: "none !important",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));

const TicketsManager = () => {
    const classes = useStyles();
    const { tenant } = useTenant();

    const [newChatModalOpen, setNewChatModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleCloseOrOpenChat = (chat) => {
        setNewChatModalOpen(false);
        if (chat !== undefined && chat?.uuid !== undefined) {
            navigate(`/${tenant?.slug}/chats/${chat?.uuid}`);
        }
    }

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <NewChatModal
                modalOpen={newChatModalOpen}
                onClose={(chat) => handleCloseOrOpenChat(chat)}
                type="new"
            />
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setNewChatModalOpen(true)}
                >
                    {i18n.t("translation.validation.buttons.newTicket")}
                </Button>
                <TutorialModalButton content="tutorial" />
            </Paper>
            <Paper className={classes.ticketsList}>
                <ChatsList />
            </Paper>
        </Paper>
    );
};

export default TicketsManager;
