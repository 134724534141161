import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Badge,
    Button,
    Divider,
    IconButton,
    InputBase,
    Paper,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    Eye,
    EyeOff,
    FilterX,
    Filter as ListFilter,
    MessageCirclePlus,
    Search,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import "../../global.css";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import NewTicketModal from "../NewTicketModal";
import { SearchParamModal } from "../SearchParamsModal";
import TabPanel from "../TabPanel";
import { TagsFilter } from "../TagsFilter";
import TicketsList from "../TicketsList";
import TicketsQueueSelect from "../TicketsQueueSelect";
function TicketsManager() {
    const classes = {
        ticketsHeader: "flex p-2 justify-between",
        ticketsWrapper: "h-full flex flex-col overflow-hidden relative",
        ticketsList: "relative h-full flex-col overflow-hidden",
        searchInputWrapper: "bg-slate-100 p-1 flex-1 mr-1",
        searchInput: "bg-slate-50 flex-1 mr-1 focus:bg-slate-500 w-full",
        ticketOptionsBox: "flex justify-between items-center ",
    };

    const { settings, getSettingValue } = useSettings();

    const searchInputRef = useRef();
    const [searchParam, setSearchParam] = useState({
        term: "",
        status: "",
        filter: "",
    });

    const [tab, setTab] = useState("open");
    const [oldTab, setOldTab] = useState("open");
    const [tabOpen, setTabOpen] = useState("open");
    const [showFilter, setShowFilter] = useState(false);

    const { whatsApps } = useContext(WhatsAppsContext);
    const [showGroups, setShowGroups] = useState(false);

    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [searchParamModalOpen, setSearchParamModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);

    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [groupsCount, setGroupsCount] = useState(0);

    const [selectedTags, setSelectedTags] = useState([]);

    const { tenant, userTenant, userTenantAdminRole } = useTenant();

    // const userQueueIds = userTenant?.queues?.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (!userTenant) return;
        if (userTenantAdminRole) {
            setShowAllTickets(true);
        }
        setSelectedQueueIds(userTenant?.queues?.map((q) => q.id))
    }, [userTenant, userTenantAdminRole]);

    let searchTimeout;
    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();

        clearTimeout(searchTimeout);

        if (searchedTerm === "") {
            setSearchParam((prevState) => ({
                ...prevState,
                term: searchedTerm,
            }));
            setTab(oldTab);
            return;
        }

        searchTimeout = setTimeout(() => {
            setSearchParam((prevState) => ({
                ...prevState,
                term: searchedTerm,
            }));

            if ((searchedTerm.length > 0) & (tab !== "search")) {
                setOldTab(tab);
                setTab("search");
            }
        }, 500);
    };

    const handleSelectedTags = (selectedTags) => {
        const tags = selectedTags.map((t) => t.id);
        setSelectedTags(tags);
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };
    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        }
    };
    const handleChangeTabOpen = (e, newValue) => {
        setTabOpen(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    useEffect(() => {
        if (!whatsApps) return

        // const enabledGroups = whatsApps.find(w => w?.checkGroups === true && w?.status === "CONNECTED")
        const enabledGroups = whatsApps.find(w => w?.checkGroups === true)
        if (enabledGroups) setShowGroups(true)
    }, [whatsApps])

    return (
        <Paper elevation={0} className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <SearchParamModal
                modalOpen={searchParamModalOpen}
                searchParamState={setSearchParam}
                onClose={() => {
                    setSearchParamModalOpen(false);
                }}
            />
            <Paper>
                <div className={classes.ticketsHeader}>
                    <Typography variant="h3" className="font-sans ml-2">
                        Conversas:
                    </Typography>
                    <div className="flex mr-0 justify-between">
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={i18n.t(
                                "translation.tickets.tabs.search.title"
                            )}
                        >
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setTab(oldTab);

                                    setShowFilter(!showFilter);

                                }}
                            >
                                {showFilter ? (
                                    <FilterX className="text-red-400" />
                                ) : (
                                    <ListFilter className="text-slate-500" />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Can
                            role={userTenant?.role}
                            perform="ticket-manager:show:all"
                            yes={() => (
                                <Tooltip
                                    arrow
                                    placement="bottom"
                                    title={
                                        showAllTickets
                                            ? i18n.t(
                                                  "translation.validation.buttons.showMe"
                                              )
                                            : i18n.t(
                                                  "translation.validation.buttons.showAll"
                                              )
                                    }
                                >
                                    <IconButton
                                        color="inherit"
                                        size="small"
                                        onClick={() =>
                                            setShowAllTickets(
                                                (prevState) => !prevState
                                            )
                                        }
                                    >
                                        {showAllTickets ? (
                                            <Eye className="text-slate-500" />
                                        ) : (
                                            <EyeOff className="text-slate-500 opacity-80" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                        />
                        <Button
                            color="info"
                            variant="outlined"
                            size="small"
                            onClick={() => setNewTicketModalOpen(true)}
                        >
                            <MessageCirclePlus size={14} />{" "}
                            {i18n.t("translation.validation.buttons.newTicket")}
                        </Button>
                    </div>
                </div>
                {showFilter ? (
                    <>
                        <Paper
                            square
                            elevation={0}
                            className={classes.ticketOptionsBox}
                        >
                            <div className={classes.searchInputWrapper}>
                                <InputBase
                                    startAdornment={
                                        <IconButton
                                            className={classes.searchIcon}
                                            color="info"
                                            onClick={() =>
                                                setSearchParamModalOpen(true)
                                            }
                                        >
                                            <Search size={18} />
                                        </IconButton>
                                    }
                                    className={classes.searchInput}
                                    inputRef={searchInputRef}
                                    placeholder={i18n.t(
                                        "translation.tickets.search.placeholder"
                                    )}
                                    type="search"
                                    onChange={handleSearch}
                                />
                            </div>
                            <TicketsQueueSelect
                                style={{ marginLeft: 6 }}
                                selectedQueueIds={selectedQueueIds}
                                userQueues={userTenant?.queues}
                                onChange={(values) =>
                                    setSelectedQueueIds(values)
                                }
                            />
                        </Paper>
                        <div className="mt-2">
                            <TagsFilter onFiltered={handleSelectedTags} />
                            <Divider />
                        </div>
                    </>
                ) : null}
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                >
                    {searchParam.term.length > 0 ? (
                        <Tab
                            value={"search"}
                            label={i18n.t(
                                "translation.tickets.tabs.search.title"
                            )}
                        />
                    ) : null}

                    <Tab
                        value={"open"}
                        label={i18n.t("translation.tickets.tabs.open.title")}
                    />
                    {settings &&
                    settings.length > 0 &&
                    getSettingValue("submitTicketForReview") === "enabled" ? (
                        <Tab
                            value={"waiting"}
                            label={i18n.t(
                                "translation.tickets.tabs.waiting.title"
                            )}
                        />
                    ) : null}

                    <Tab
                        value={"closed"}
                        label={i18n.t("translation.tickets.tabs.closed.title")}
                    />
                </Tabs>
            </Paper>
            <TabPanel
                value={tab}
                name="open"
                className={classes.ticketsWrapper}
            >
                <Tabs
                    value={tabOpen}
                    onChange={handleChangeTabOpen}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={openCount}
                                color="primary"
                                overlap="rectangular"
                            >
                                {i18n.t(
                                    "translation.ticketsList.assignedHeader"
                                )}
                            </Badge>
                        }
                        value={"open"}
                    />
                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={pendingCount}
                                color="secondary"
                                overlap="rectangular"
                            >
                                {i18n.t(
                                    "translation.ticketsList.pendingHeader"
                                )}
                            </Badge>
                        }
                        value={"pending"}
                    />
                     {showGroups ?
                        <Tab
                            label={
                                <Badge
                                    className={classes.badge}
                                    badgeContent={groupsCount}
                                    color="secondary"
                                    overlap="rectangular"
                                >
                                    {i18n.t("translation.ticketsList.groupsHeader")}
                                </Badge>
                            }
                            value={"groups"}
                        />
                        : ''}
                </Tabs>
                <Paper className={classes.ticketsList}>
                    <TicketsList
                        status="open"
                        isGroup={false}
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setOpenCount(val)}
                        style={applyPanelStyle("open")}
                    />
                    <TicketsList
                        status="pending"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setPendingCount(val)}
                        style={applyPanelStyle("pending")}
                    />
                     {showGroups ?
                        <TicketsList
                            status="open"
                            isGroup={true}
                            showAll={showAllTickets}
                            selectedQueueIds={selectedQueueIds}
                            updateCount={(val) => setGroupsCount(val)}
                            style={applyPanelStyle("groups")}
                        />
                        : ''}
                </Paper>
            </TabPanel>
            {settings &&
            settings.length > 0 &&
            getSettingValue("submitTicketForReview") === "enabled" ? (
                <TabPanel
                    value={tab}
                    name="waiting"
                    className={classes.ticketsWrapper}
                >
                    <TicketsList
                        status="waiting"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                    />
                </TabPanel>
            ) : (
                ""
            )}
            <TabPanel
                value={tab}
                name="closed"
                className={classes.ticketsWrapper}
            >
                <TicketsList
                    status="closed"
                    showAll={showAllTickets}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
            <TabPanel
                value={tab}
                name="search"
                className={classes.ticketsWrapper}
            >
                {/* <TagsFilter onFiltered={handleSelectedTags} /> */}
                <TicketsList
                    searchParam={searchParam}
                    tags={selectedTags}
                    showAll={true}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
        </Paper>
    );
}

export default TicketsManager;
